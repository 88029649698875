<div id="delphire-library">
  <!-- * Library Name -->
  <h2 class="library-name" [innerHtml]="library.name"></h2>

  <!-- * Library Description -->
  <p class="library-description" *ngIf="library.description" [innerHtml]="library.description"></p>

  <div fxLayout="row" fxLayout.xs="column">
    <!-- * Table Filter -->
    <mat-form-field class="library-filter" fxFlex appearance="standard">
      <mat-label>Filter Library Items</mat-label>
      <input fxFlex matInput (keyup)="applyFilter($event)" placeholder="Filter Library" #input />
    </mat-form-field>

    <div fxFlex fxHide fxShow.gt-xs></div>

    <!-- * Table Pagination -->
    <mat-paginator [pageSizeOptions]="[10, 25, 50, 100]" aria-label="Select Page"></mat-paginator>
  </div>

  <div class="mat-elevation-z8">
    <table mat-table [dataSource]="dataSource" matSort>
      <!-- * Icon Column -->
      <div matColumnDef="icon" fxHide.xs>
        <th mat-header-cell *matHeaderCellDef mat-sort-header fxHide.xs></th>
        <td class="align-top" mat-cell *matCellDef="let row" fxHide.xs>
          <ng-icon class="icon" name="{{ getTypeIcon(row.parameters.type) }}" size="20px"></ng-icon>
        </td>
      </div>

      <!-- * Name Column -->

      <div matColumnDef="name" fxHide.xs>
        <th class="details" mat-header-cell *matHeaderCellDef mat-sort-header>
          Details
        </th>
        <td class="align-top" class="name" mat-cell *matCellDef="let row" (click)="handleLink(row)">
          <div class="name-description" fxLayout="column">
            <p class="type" [innerHtml]="getType(row)"></p>
            <p class="name" [innerHtml]="row.name"></p>
            <div class="responsive-actions" fxHide fxShow.xs fxLayout="row">
              <div fxFlex="20" fxLayoutAlign="start center">
                <ng-icon class="icon" name="{{ getTypeIcon(row.parameters.type) }}" size="20px"></ng-icon> <span
                  class="item-type">{{ row.parameters.type }}</span>
              </div>
              <div fxFlex="50" fxLayoutAlign="center center" [ngClass]="{ hand: !row.isOldLink }"
                (click)="toggleFavorite(row)">
                <div [hidden]="row.isFavorite">
                  <ng-icon matTooltip="Add Favorite" matTooltipPosition="above" class="not-favorite"
                    name="{{ getTypeIcon('favorite') }}" size="20px"></ng-icon> Favorite
                </div>
                <div [hidden]="!row.isFavorite">
                  <ng-icon matTooltip="Remove Favorite" matTooltipPosition="above" class="is-favorite"
                    name="{{ getTypeIcon('favorite', true) }}" size="20px"></ng-icon> Favorited
                </div>
              </div>
              <div fxFlex="30" fxLayoutAlign="end center">
                <button mat-flat-button
                  [ngClass]="{ launch: !row.progress || row.progress == 0, resume: row.progress > 0 && row.progress < 100, relaunch: row.progress == 100 }"
                  fxLayoutAlign="start center" (click)="handleLink(row)"></button>
              </div>
            </div>
            <p fxShow fxHide.xs class="description" [innerHTML]="getDescription(row)"></p>
            <p fxHide fxShow.xs class="description truncated" [innerHTML]="getDescription(row )"></p>
          </div>
        </td>
      </div>

      <!-- * ModalGPT Column -->
      <div matColumnDef="gpt" fxHide.xs>
        <th class="center-text" mat-header-cell *matHeaderCellDef fxHide.xs></th>
        <td class="align-top center-text" mat-cell *matCellDef="let row" fxHide.xs>
          <div class="hand" (click)="gptSidebar.open(row)" *ngIf="row.gptInfo">
            <img src="/assets/chat-gpt/ai.svg" />
          </div>
        </td>
      </div>

      <!-- * Favorite Column -->
      <div matColumnDef="favorite" fxHide.xs>
        <th class="center-text" mat-header-cell *matHeaderCellDef fxHide.xs>Favorite</th>
        <td class="align-top center-text" mat-cell *matCellDef="let row" fxHide.xs>
          <div *ngIf="!row.isOldLink" (click)="toggleFavorite(row)" [ngClass]="{ hand: !row.isOldLink }">
            <ng-container *ngIf="canFavorite(row)">
              <ng-icon matTooltip="Add Favorite" matTooltipPosition="above" class="not-favorite"
                name="{{ getTypeIcon('favorite') }}" size="20px" [hidden]="row.isFavorite"></ng-icon>
              <ng-icon matTooltip="Remove Favorite" matTooltipPosition="above" class="is-favorite"
                name="{{ getTypeIcon('favorite', true) }}" size="20px" [hidden]="!row.isFavorite"></ng-icon>
            </ng-container>
          </div>
        </td>
      </div>

      <!-- * Button Column -->
      <div matColumnDef="action" fxHide.xs>
        <th mat-header-cell *matHeaderCellDef mat-header fxHide.xs></th>
        <td class="align-top" mat-cell *matCellDef="let row" fxHide.xs>
          <button mat-flat-button [ngClass]="{
              launch: !row.progress || row.progress == 0,
              resume: row.progress > 0 && row.progress < 100,
              relaunch: row.progress == 100
            }" fxLayoutAlign="start center" (click)="handleLink(row)"></button>
        </td>
      </div>

      <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
      <tr mat-row *matRowDef="let row; columns: displayedColumns"></tr>

      <!-- * Row shown when there is no matching data -->
      <tr class="mat-row" *matNoDataRow>
        <td class="mat-cell" colspan="5">
          No data matching the filter "{{ input.value }}"
        </td>
      </tr>
    </table>
  </div>
  
  <app-chat-gpt-sidebar [gptTrainingId]="gptTrainingId" [documentTitle]="gptDocumentTitle">

  </app-chat-gpt-sidebar>


  </div>