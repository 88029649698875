<div class="gpt-sidebar">
  <div class="document-chat" fxFlex fxLayout="column" [ngClass]="{ 'open': gptOpen }">

    <div class="top" fxLayout="column">
      <div class="header" fxLayout="row">
        <div fxFlex="50" fxLayout="row" fxLayoutAlign="start center">
          <div class="title">ModalGPT</div>
        </div>
        <div class="about" fxFlex fxLayoutAlign="end center">
          <a href="javascript:void(0)">What is ModalGPT?</a>
          <ng-icon name="HeroInformationCircle" size="1.5em"></ng-icon>
        </div>
      </div>
      <div class="document">
        <div class="title">{{ gptDocumentTitle }}</div>
      </div>
    </div>

    <hr>

    <div class="middle" fxLayout="column-reverse">
      <div *ngFor="let qaPair of gptChatSession; index as qaIdx" fxLayout="column">
        <div class="question" fxLayout="row" fxLayoutAlign="end start">
          <div class="text">{{ qaPair.message.text }}</div>
          <div class="avatar">
            <img src="/assets/chat-gpt/avatar-user.svg" />
          </div>
        </div>
        <div class="response" fxLayout="row" fxLayoutAlign="start start">
          <div class="avatar">
            <img src="/assets/chat-gpt/avatar-rx.svg" />
          </div>
          <div fxLayout="column" fxLayoutAlign="center center">
            <div class="loader" *ngIf="!qaPair.response.text">
              <div class="dot-pulse"></div>
            </div>
            <div *ngIf="qaPair.response.text">
              <div fxLayout="row">
                <div class="text">{{ qaPair.response.text }}</div>
                <div fxLayoutAlign="start start" fxLayout="row" class="thumbs">
                  <ng-icon class="thumb" name="hero-thumb-up" size="20px"
                    *ngIf="!qaPair.response.feedback || qaPair.response.feedback=='up'"
                    [ngClass]="{ 'disabled': qaPair.response.feedback}" (click)="giveFeedback(qaIdx, 'up')"></ng-icon>

                  <ng-icon class="thumb" name="hero-thumb-down" size="20px"
                    *ngIf="!qaPair.response.feedback || qaPair.response.feedback=='down'"
                    [ngClass]="{ 'disabled': qaPair.response.feedback}" (click)="giveFeedback(qaIdx, 'down')"></ng-icon>
                </div>

              </div>

              <div class="knowledge-link">
                <a (click)="handleLink(gptSelectedRow)">Click to see the knowledge behind this
                  answer</a>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>

    <hr>

    <div class="bottom" fxLayout="row">
      <input type="text" [(ngModel)]="gptQuestionAsked" (keyup)="onAskKeyUp($event)" placeholder="Ask a question" />
      <div class="ask-button" (click)="askGPT()" [ngClass]="{ 'disabled': !gptQuestionAsked}" fxLayout="row"
        fxLayoutAlign="center center">ASK →</div>
    </div>

    <div class="rxds">
      Powered by RX Data Science
    </div>

  </div>

  <div class="cover" [ngClass]="{ 'open': gptOpen }" (click)="close()"></div>