import { Component, Input, OnInit } from '@angular/core'
import { ActivatedRoute } from '@angular/router'
import { SpinnerVisibilityService } from 'ng-http-loader'
import { environment } from 'src/environments/environment'
import { Task } from '../interfaces/task'
import { DelphireLinkService } from '../services/delphire-link.service'
import { ModalGptService } from '../services/modal-gpt.service'
interface gptChatSession {
  message: {
    id: number
    text: string
  }
  response: {
    id: number
    text: string
    answerId: number
    trainingLink: string
    feedback?: string | null
  }
}
@Component({
  selector: 'app-chat-gpt-sidebar',
  templateUrl: './chat-gpt-sidebar.component.html',
  styleUrls: ['./chat-gpt-sidebar.component.sass']
})
export class ChatGptSidebarComponent implements OnInit {
  verbose: boolean = environment.production ? false : true

  gptDocumentTitle: string | undefined
  gptQuestionAsked: string | undefined
  gptQuestionCount: number = 0
  gptChatSession: gptChatSession[] = []
  gptOpen: boolean = false
  gptSelectedRow: Task

  @Input() documentTitle: string | undefined;
  @Input() gptTrainingId: number | null;

  @Input() item: Task | undefined;



  constructor(
    private modalGPT: ModalGptService,
    private linkService: DelphireLinkService,
    private route: ActivatedRoute,
    private loader: SpinnerVisibilityService
  ) { }



  ngOnInit(): void {
  }




  public open(item: Task) {
    if (item.gptInfo && item.parameters) {
      this.gptOpen = true
      this.gptDocumentTitle = item.name
      this.gptTrainingId = item.gptInfo.training_id
      this.gptSelectedRow = item
      if (this.verbose) {
        console.log(
          '%c[ resource ID ]',
          'color: yellow',
          item.parameters.resourceId
        )
        console.log('%c[ resource name ]', 'color: yellow', item.name)
        console.log(
          '%c[ gtp training id ]',
          'color: yellow',
          this.gptTrainingId
        )
      }
    }
  }


  close() {
    this.gptOpen = false
    this.gptDocumentTitle = ''
    this.gptQuestionAsked = undefined
    this.gptQuestionCount = 0
    this.gptTrainingId = null
    this.gptChatSession = []
  }


  askGPT() {
    if (this.gptQuestionAsked) {
      console.log(this.gptSelectedRow)
      const displayQuestion = this.gptQuestionAsked
      const regex = /\?/gi
      const question = this.gptQuestionAsked.replace(regex, '')
      this.gptQuestionAsked = question

      if (this.verbose) {
        console.log('%c[ askModalGPT() ]', 'color: cyan', displayQuestion)
      }


      let ask: gptChatSession = {
        message: { id: this.gptQuestionCount, text: displayQuestion },
        response: {
          id: this.gptQuestionCount,
          text: '',
          answerId: 0,
          trainingLink: ''
        }
      }

      this.gptChatSession.unshift(ask)




      let trainingId = this.gptSelectedRow?.gptInfo?.training_id || 0
      let agentId = this.gptSelectedRow?.gptInfo?.agent_id || 0

      this.modalGPT.getResponse(trainingId, this.gptQuestionAsked, agentId).subscribe((gptRes) => {
        let responseText = gptRes.body[0].details[0].response
        let responseId = gptRes.body[0].details[0]["answer id"]
        let trainingLink = gptRes.body[0].details[0]["training link"]

        if (this.verbose) {
          console.log('%c[ info ]', 'color: cyan', gptRes)
          ask.response = {
            id: responseId,
            text: responseText,
            answerId: ask.response.id,
            trainingLink: trainingLink
          }

        }
      },
        (err) => {
          ask.response = {
            id: -1,
            text: "Sorry, I'm unable to answer this question.",
            answerId: -1,
            trainingLink: ""
          }


        }
      )




      this.gptQuestionAsked = undefined

      //! for testing

      this.gptQuestionCount++

      if (this.verbose) {
        console.log(
          '%c[ this.gptChatSession ]',
          'color: orangered',
          this.gptChatSession
        )
      }
    }
  }

  onAskKeyUp(event: any): void {
    if (event.key === 'Enter') {
      this.askGPT()
    }
  }


  giveFeedback(qaIdx: number, feedback: string): void {
    this.gptChatSession[qaIdx].response.feedback = feedback
    let answerId = this.gptChatSession[qaIdx].response.answerId
    this.modalGPT.shareFeedback(answerId, feedback).subscribe((feedbackRes) => {
      console.log('%c[ info ]', 'color: cyan', feedbackRes)
    })


  }

  async handleLink(task: Task): Promise<void> {
    if (
      task.resource?.type === 'audio' ||
      task.resource?.type === 'video'
    ) {
      const {
        completed,
        started,
        progress,
        mediaCompleted,
        mediaLastTime,
        mediaPercentage
      } = task
      task.resource = {
        ...task.resource,
        completed,
        started,
        progress,
        mediaCompleted,
        mediaLastTime,
        mediaPercentage
      }
    }
    if (task.resource?.type === 'document') {
      const {
        completed,
        started,
        progress,
        documentCompleted,
        documentPercentage,
        documentLastTime
      } = task
      task.resource = {
        ...task.resource,
        completed,
        started,
        progress,
        payload: {
          ...task.resource.payload,
          documentCompleted,
          documentPercentage,
          documentLastTime,
          currentPage: documentLastTime
        },
        documentCompleted,
        documentPercentage,
        documentLastTime
      }
    }
    this.linkService.handleLink({
      type: task.resource?.type,
      resource: task.resource,
      route: this.route
    })
  }


}
