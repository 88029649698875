// This file can be replaced during build by using the `fileReplacements` array.
// `ng build` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.
import packageInfo from '../../package.json'

export const environment = {
  production: false,
  analyticsKey: 'testing',
  progressKey: 'ProgressTesting',
  name: 'pillar',
  appName: 'The Pillar Spot',
  urlScheme: 'delphirepillar',
  appId: 'com.bocodigital.pillar',
  version: packageInfo.version,
  homePage: [''],
  scormEngine: 'https://api.modal.thepillarspot.com',
  authConfig: {
    domain: 'thepillarspot.us.auth0.com',
    clientId: 'fclmGd8Ne5NMqAw4afnBqTuCp8BI3Hf0',
    audience: 'https://iam.modal.thepillarspot.com',
    responseType: 'token id_token'
  },
  firebaseConfig: {
    apiKey: 'AIzaSyCgr3k2Yl_UiUgSQJWFAAqjh4okzAFFaMs',
    authDomain: 'delphire-io-pillar.firebaseapp.com',
    databaseURL: 'https://delphire-io-pillar-default-rtdb.firebaseio.com',
    projectId: 'delphire-io-pillar',
    storageBucket: 'delphire-io-pillar.appspot.com',
    messagingSenderId: '384929052237',
    appId: '1:384929052237:web:25cefcbf51708608564e04',
    measurementId: 'G-B7DJQ803VL',
    vapidKey: 'BCqLprsZjNZjygjXdf-xDxQkot5Sm8Kpt9R4aIda-WlOLxt3IF-P1T8EnotlBP9gqPE6TYQPZjQ-8dXybzy7Dzc',
    fallbackUrl : 'https://bocoweb.bocodigital.com/ios/apps/delphire-pillar/v4',
    iamId: 'delphire-io-pillar-state'

  },
  pspdfkit: {
    nativeLicense:
      'Q64bSlGCsvZafSCCobcDaeTgXk90GNTE9ZzYNvRwtXeGw-dRo8dKV-1jdk2kSpzLFhGyHC_b2hZ_ibn0yTYCUzP6WtOb70yBB4mTnQ2tWULOvUMeRdOpAK-Ti5kvtJ7G7AoXXAdnucxINJiiB7QY-cq5ElIXiVMWcMJN_v12lS0_T1zVllNSGLUgU9V3IirnDfHa0iPGvCHB-utlY6UlnWHldZymRnQtdl8r5eG2xKGmDyqQ8k1Y6QP7ne78wuNH5Wv97FACNyqCz3PSx3oz60nAsQMF8Q9Suvkl4psgXhCVXtbT37-DonYedFuBK_5JgSUCsvH_60c0gjpWiNlmYFZ_7Rws7u1Nu3Ksw48LY1zgJTC7WfeRz9kmbTAIVDh7rU5KlCS0v--32a39YCKbTc8-S5zT6QgmnUNh-hUxal6rigUFjoJD_JL4j9K3x4aAvMYJvX_x69gq-nP6BRKV4iwmfl1nEVjPP_1lt0L9MYzx9rCnIbO5fD7pZw4khpCohVlyAWPKZa6eUfmX3dZtE2l0zSPUv44EvJsPC-wWAHzTnV7jFS-V3LnlW2DyihHFrY8yITpfonK2JQCDBBwProguADNSqWsobsU7vqzRmvM=',
    license:
      'Q64bSlGCsvZafSCCobcDaeTgXk90GNTE9ZzYNvRwtXeGw-dRo8dKV-1jdk2kSpzLFhGyHC_b2hZ_ibn0yTYCUzP6WtOb70yBB4mTnQ2tWULOvUMeRdOpAK-Ti5kvtJ7G7AoXXAdnucxINJiiB7QY-cq5ElIXiVMWcMJN_v12lS0_T1zVllNSGLUgU9V3IirnDfHa0iPGvCHB-utlY6UlnWHldZymRnQtdl8r5eG2xKGmDyqQ8k1Y6QP7ne78wuNH5Wv97FACNyqCz3PSx3oz60nAsQMF8Q9Suvkl4psgXhCVXtbT37-DonYedFuBK_5JgSUCsvH_60c0gjpWiNlmYFZ_7Rws7u1Nu3Ksw48LY1zgJTC7WfeRz9kmbTAIVDh7rU5KlCS0v--32a39YCKbTc8-S5zT6QgmnUNh-hUxal6rigUFjoJD_JL4j9K3x4aAvMYJvX_x69gq-nP6BRKV4iwmfl1nEVjPP_1lt0L9MYzx9rCnIbO5fD7pZw4khpCohVlyAWPKZa6eUfmX3dZtE2l0zSPUv44EvJsPC-wWAHzTnV7jFS-V3LnlW2DyihHFrY8yITpfonK2JQCDBBwProguADNSqWsobsU7vqzRmvM='
  },
  firebasePaths: {
    state: 'environment/production/iam/state/',
    space: 'environments/production/'
  },
  appLanguage: {
    general: {
      networkConnectionMessageHeader: 'Network connection is required',
      networkConnectionMessage:
        'I am sorry. To use this resource, you must be connected to the Internet.',
      connectionLost: 'Your connection to the internet was lost.',
      connectionFound: 'Your internet connection seems to have returned.',
      reloadMessage: 'Please press OK to reload.',
      showMe: 'Show Me',
      downLoading: 'Downloading',
      dismiss: 'Dismiss',
      disclaimer:
        'Confidential – For internal educational use only. Execution to content must align to role responsibilities and comply with company policies & procedures.'
    },
    topNav: {
      loggedInAs: 'Logged in as',
      since: 'since',
      availableLayouts: 'Available Layouts',
      favorites: 'Favorites',
      preferences: 'Preferences',
      logOutNow: 'Logout now',
      yes: 'Yes',
      no: 'No',
      areYouSure: 'Are you sure?',
      sorry: 'Sorry',
      okay: 'Okay',
      maybeLater: 'Maybe Later'
    },
    modals: {
      workingOffline: 'You are working offline',
      preferences: {
        profile: 'Profile',
        dataManagement: 'Data Management',
        total: 'Total',
        allStorage: 'All Storage',
        features: 'Features',
        notifications: 'Notifications',
        defaultLayout: 'Default dashboard',
        defaultLayoutMessage:
          'Select a dashboard below to change the default dashboard.',
        defaultLayoutDisclaimer:
          'the dashboard can always be changed using the drop down menu at the top right at any time.',
        close: 'Close'
      },
      updateInProgressHeader: 'Update in progress',
      updateInProgressMessage:
        'Your dashboard and resources are being updated to reflect recent changes.',
      contentUnavailableHeader: 'This content is no longer available.',
      contentUnavailableMessage:
        'This content has been disabled. Please return to the main dashboard.',
      chooseLayoutHeader: 'Would you like to make this your default dashboard?',
      chooseLayoutMessage:
        'You can change the dashboard later by clicking on the avatar in the upper right corner of the application. You can also change your default dashboard by going to preferences.',
      logout: 'Logout',
      logOutMessage: 'You will be redirected to the login screen momentarily.'
    },
    widget: {
      disabled: 'Disabled',
      waiting: 'Waiting',
      week: 'Week',
      todaysTasks: 'Todays Tasks',
      noTasks: 'Today there are no tasks.',
      youHaveNoTasks: '',
      forCurrentWeek: '',
      minutes: 'mins',
      keyObjectives: 'Key Objectives',
      todoThisWeek: 'Tasks for this week'
    },
    library: {
      noFavoritesHeader: 'No favorites',
      noFavoritesMessage: 'You have not marked any favorites.',
      folderMessage: 'inside this folder',
      pages: 'pages',
      open: 'Open',
      myFavorites: 'My Favorites'
    },
    agenda: {
      viewPdf: 'View PDF',
      time: 'Time',
      description: 'Description',
      location: 'Location'
    },
    roadmap: {
      monday: 'Monday',
      tuesday: 'Tuesday',
      wednesday: 'Wednesday',
      thursday: 'Thursday',
      friday: 'Friday',
      saturday: 'Saturday',
      sunday: 'Sunday',
      week: 'Week',
      noTasksThisDay: '',
      checkAll: 'Check all',
      unCheckAll: 'Uncheck all',
      acknowledgement: '',
      acknowledgementModalSubmit: ''
    },
    quiz: {
      quiz: 'Knowledge Check',
      begin: 'Begin',
      close: 'Close',
      submit: 'Submit',
      correct: 'Correct',
      incorrect: 'Incorrect',
      continue: 'Continue',
      sorry: 'You did not successfully achieve a passing score.',
      congratulations: 'Congratulations!'
    }
  }
}

/*
 * For easier debugging in development mode, you can import the following file
 * to ignore zone related error stack frames such as `zone.run`, `zoneDelegate.invokeTask`.
 *
 * This import should be commented out in production mode because it will have a negative impact
 * on performance if an error is thrown.
 */
// import 'zone.js/plugins/zone-error';  // Included with Angular CLI.
