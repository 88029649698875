import * as _ from 'lodash'
import { ActivatedRoute } from '@angular/router'
import { AfterViewInit, Component, OnInit, ViewChild } from '@angular/core'
import { ChatGptSidebarComponent } from '../chat-gpt-sidebar/chat-gpt-sidebar.component'
import { DelphireLinkService } from '../services/delphire-link.service'
import { environment } from '../../environments/environment'
import { FirebaseService } from '../services/firebase.service'
import { gptChatSession } from '../interfaces/modal-gpt'
import { IconService } from '../services/icon.service'
import { Library } from '../interfaces/library'
import { LocalStorageService } from '../services/local-storage-service.service'
import { MatPaginator } from '@angular/material/paginator'
import { MatSort } from '@angular/material/sort'
import { MatTableDataSource } from '@angular/material/table'
import { ModalGptService } from '../services/modal-gpt.service'
import { ProgressService } from '../services/progress.service'
import { SpinnerVisibilityService } from 'ng-http-loader'
import { Task } from '../interfaces/task'
import { TaskService } from '../services/task.service'
import { UserPreferencesService } from '../services/user-preference.service'

@Component({
  selector: 'app-library',
  templateUrl: './library.component.html',
  styleUrls: ['./library.component.sass']
})
export class LibraryComponent implements OnInit, AfterViewInit {
  verbose: boolean = environment.production ? false : true
  library: Library = {}
  tasks: Task[] = []
  libraryId: string | undefined
  loadingAnalytics: boolean = false
  // deviceInfo: DeviceInfo | undefined
  favoritesLibrary: boolean = false

  dataSource = new MatTableDataSource<Task>(this.tasks)
  displayedColumns: string[] = ['icon', 'name', 'gpt', 'favorite', 'action']

  gptOpen: boolean = false
  gptTrainingId: number | null
  gptDocumentTitle: string | undefined
  gptQuestionAsked: string | undefined
  gptQuestionCount: number = 0
  gptItems: any[] = []
  gptChatSession: gptChatSession[] = []

  @ViewChild(ChatGptSidebarComponent) gptSidebar: ChatGptSidebarComponent;
  @ViewChild(MatPaginator) paginator!: MatPaginator
  @ViewChild(MatSort) sort!: MatSort

  constructor(
    private route: ActivatedRoute,
    private localStorage: LocalStorageService,
    private fbS: FirebaseService,
    private progressService: ProgressService,
    private taskService: TaskService,
    private iconService: IconService,
    private linkService: DelphireLinkService,
    private spinner: SpinnerVisibilityService,
    public userPreferences: UserPreferencesService,
    private modalGPT: ModalGptService
  ) { }

  applyFilter(event: Event) {
    const filterValue = (event.target as HTMLInputElement).value
    this.dataSource.filter = filterValue.trim().toLowerCase()

    if (this.dataSource.paginator) {
      this.dataSource.paginator.firstPage()
    }
  }

  canFavorite(item: Task): boolean {
    const excluded = ['External Link']
    if (item.type && excluded.indexOf(item.type) > -1) return false
    if (!item) return false
    if (item.link?.id == item.id) return false
    return true
  }

  getType(item: Task): any {
    if (item.type == 'Resource') return item.resource?.type
    return item.parameters?.type
  }

  getDescription(item: Task): any {
    let description: any

    switch (item.type?.toLocaleLowerCase()) {
      case 'resource':
        description = item.resource?.description
        break

      case 'library':
        description = item.library?.description
        break

      case 'quiz':
        description = item.quiz?.description
        break

      case 'external link':
        description = item.link?.description
        break

      case 'roadmap':
        description = item.roadmap?.description
        break

      default:
        break
    }

    return description
  }

  toggleFavorite(item: Task) {
    if (item.isOldLink) return
    console.log('%c[ add to favs ]', 'color: aqua', item.type, item)

    let favorite = {}

    switch (item.type?.toLocaleLowerCase()) {
      case 'course':
        if (this.verbose) {
          console.log('%c[ course ]', 'color: yellow')
        }
        favorite = { id: item.course?.id, type: item.type }
        break

      case 'roadmap':
        if (this.verbose) {
          console.log('%c[ roadmap ]', 'color: yellow')
        }
        favorite = { id: item.roadmap?.id, type: item.type }
        break

      case 'resource':
        if (this.verbose) {
          console.log('%c[ resource ]', 'color: yellow')
        }
        favorite = { id: item.resource?.id, type: item.parameters?.type }
        break

      case 'library':
        if (this.verbose) {
          console.log('%c[ library ]', 'color: yellow')
        }
        favorite = { id: item.library?.id, type: item.type }
        break

      case 'quiz':
        if (this.verbose) {
          console.log('%c[ quiz ]', 'color: yellow')
        }
        favorite = { id: item.quiz?.id, type: item.type }
        break

      case 'external link':
        if (this.verbose) {
          console.log('%c[ external link ]', 'color: yellow')
        }
        const linkId: string | undefined =
          item.link?.parameters?.linkId != null
            ? item.link.parameters.linkId
            : item.link?.parameters?.externalLinkId
        favorite = { id: linkId, type: item.type }
        break

      default:
        break
    }

    this.userPreferences.setFavorites(favorite)
    item.isFavorite = !item.isFavorite
  }

  async handleLink(task: Task): Promise<void> {
    console.log('task', task)
    if (task.type) {
      switch (task.type.toLocaleLowerCase()) {
        case 'course':
          this.linkService.handleLink({
            type: task.type.toLocaleLowerCase(),
            id: task.course?.id || task.courseId,
            route: this.route.parent
          })
          break
        case 'roadmap':
          this.linkService.handleLink({
            type: task.type.toLocaleLowerCase(),
            id: task.roadmap?.id || task.roadmapId,
            route: this.route.parent
          })
          break
        case 'resource':
          if (
            task.resource?.type === 'audio' ||
            task.resource?.type === 'video'
          ) {
            const {
              completed,
              started,
              progress,
              mediaCompleted,
              mediaLastTime,
              mediaPercentage
            } = task
            task.resource = {
              ...task.resource,
              completed,
              started,
              progress,
              mediaCompleted,
              mediaLastTime,
              mediaPercentage
            }
          }
          if (task.resource?.type === 'document') {
            const {
              completed,
              started,
              progress,
              documentCompleted,
              documentPercentage,
              documentLastTime
            } = task
            task.resource = {
              ...task.resource,
              completed,
              started,
              progress,
              payload: {
                ...task.resource.payload,
                documentCompleted,
                documentPercentage,
                documentLastTime,
                currentPage: documentLastTime
              },
              documentCompleted,
              documentPercentage,
              documentLastTime
            }
          }
          this.linkService.handleLink({
            type: task.resource?.type,
            resource: task.resource,
            route: this.route
          })
          break
        case 'external link':
          this.linkService.handleLink({
            type: 'externallink',
            resource: task,
            route: this.route
          })
          break
        case 'library':
          this.linkService.handleLink({
            type: task.type.toLocaleLowerCase(),
            id: task.library?.id,
            route: this.route.parent
          })
          break
        case 'quiz':
          this.linkService.handleLink({
            type: task.type.toLocaleLowerCase(),
            id: task.quiz?.id || task.quizId,
            route: this.route.parent
          })
          break

        default:
          const type = task.type.toLocaleLowerCase()
          const parameters = task.parameters as any
          this.linkService.handleLink({
            type: task.type.toLocaleLowerCase(),
            id: parameters[type + 'Id'],
            route: this.route.parent
          })
          break
      }
    }
  }

  async setData(tasks: Task[]): Promise<void> {
    tasks = _.sortBy(tasks, 'order', 'desc')
    tasks.forEach((task) => {
      // Initial Favorites assignment

      let _id: any

      if (this.favoritesLibrary) {
        _id = task.id
      } else {
        switch (task.type?.toLocaleLowerCase()) {
          case 'course':
            _id = task.parameters?.courseId
            break
          case 'roadmap':
            _id = task.parameters?.roadmapId
            break
          case 'resource':
            _id = task.parameters?.resourceId
            break
          case 'library':
            _id = task.parameters?.libraryId
            break
          case 'quiz':
            _id = task.parameters?.quizId
            break
          case 'external link':
            if (task.parameters?.linkId) {
              _id = task.parameters?.linkId
            } else {
              if (task.parameters?.externalLinkId) {
                _id = task.parameters?.externalLinkId
              } else {
                _id = task.parameters?.linkId
              }
            }
            if (task.link?.id == task.id) {
              task.isOldLink = true
            }
            break
          default:
            break
        }
      }

      const fav = _.find(this.userPreferences.favorites, {
        id: _id
      })

      if (fav) {
        task.isFavorite = true
        // if(this.verbose) { console.log('%c[ task ]', 'color: lime', task) }
      } else {
        task.isFavorite = false
        // if(this.verbose) { console.log('%c[ task ]', 'color: red', task) }
      }
    })

    this.tasks = tasks

    this.dataSource.paginator = this.paginator
    // ACL Check
    const filteredTasks = _.filter(this.tasks, { permitted: true })
    this.dataSource.data = filteredTasks

    // ? Add ModalGPT Info to Task

    this.modalGPT.getTrainingInfo().subscribe((gptResponse) => {
      this.gptItems = gptResponse.body.items
      this.gptItems.forEach((item) => {
        tasks.forEach((task) => {
          if (
            task.parameters &&
            task.parameters.resourceId === item.reference_id
          ) {
            task.gptInfo = item
            if (task.gptInfo) {
              task.gptInfo.agent_id = 3
            }
          }
        })
      })

      this.tasks = tasks
    })


    if (this.verbose) {
      console.log('%c[ this.tasks ]', 'color: yellow', this.tasks)
    }

  }

  fetchName(task: Task): string | undefined {
    switch (task.type?.toLocaleLowerCase()) {
      case 'course':
        return task.course?.name

      case 'roadmap':
        return task.roadmap?.name

      case 'resource':
        return task.resource?.name

      case 'library':
        return task.library?.name

      case 'quiz':
        return task.quiz?.name
      default:
        return task.name
    }
  }
  getTasks(items: Task[]): void {
    let tasks: Task[] = []

    Object.values(items).forEach(async (item) => {
      if (item.parameters) item.name = item.parameters.name

      this.taskService
        .get(item, 'LibraryComponent')
        .then(async (previewItem: Task) => {
          console.log('previewItem', previewItem)
          tasks.push(previewItem)
          const itemForProgress = previewItem
          if (itemForProgress.type === 'External Link') {
            this.loadingAnalytics = true
            this.progressService
              .getFirestoreAnalyticsData(itemForProgress)
              .then((task) => {
                this.loadingAnalytics = false
              })
              .catch((error) => {
                console.log('ANALYTICS ERROR', error)
              })
          } else {
            if (this.fetchName(itemForProgress)) {
              item.name = this.fetchName(itemForProgress)
            }
            this.progressService
              .getFirestoreProgressData(itemForProgress)
              .subscribe(
                (task) => {
                  task.progress =
                    this.progressService.getCompletionPercent(task)
                },
                (error) => {
                  console.log('PROGRESS ERROR', error)
                }
              )
          }

          if (tasks.length === Object.values(items).length) {
            this.setData(tasks)
          }
        })
    })
  }

  fetchLibraryData(): void {
    const { databaseUrl } = this.localStorage.get('currentSpace')
    this.spinner.show()
    this.fbS
      .db(databaseUrl)
      .object(environment.firebasePaths.space + 'libraries/' + this.libraryId)
      .subscribe((library) => {
        this.library = library
        this.getTasks(library.items)
        this.spinner.hide()
      })
  }

  async fetchFavoritesData() {
    this.library = {
      name: 'My Favorites'
    }
    await this.userPreferences.getFavorites()
    this.userPreferences.favorites$.subscribe((favoritesObs) => {
      favoritesObs.subscribe(async (favorites) => {
        const tasks: Task[] = []
        for (let index = 0; index < favorites.length; index++) {
          const fav = favorites[index]
          const task = await this.modelFavorite(fav)
          tasks.push(task as Task)
        }
        this.getTasks(tasks)
      })
    })
  }

  async modelFavorite(favorite: any) {
    const type: string = favorite.type
    const task: any = _.cloneDeep(favorite) as Task
    task.parameters = favorite.resource
    if (task.type && this.userPreferences.isBinary(task.type)) {
      if (task.resource) {
        task.type = 'resource'
        task.resource.type = type
      }

      if (task.parameters) {
        task.parameters.type = type
      }
    } else {
      if (task.parameters) {
        task.parameters.type = type
        task[task.type] = task.parameters
      }
    }

    return task as Task
  }

  getTypeIcon(type: string, solid?: boolean): string {
    if (!solid) solid = false
    return this.iconService.getIcon(type.toLowerCase(), solid)
  }

  ngOnInit(): void {
    const routeParams = this.route.snapshot.paramMap
    this.libraryId = String(routeParams.get('libraryId'))
    if (this.libraryId !== 'null') {
      this.favoritesLibrary = false
      this.fetchLibraryData()

      // Device.getInfo().then((info) => {
      //   this.deviceInfo = info
      // })

      this.route.params.subscribe((params) => {
        if (params.libraryId !== this.libraryId) {
          this.libraryId = params.libraryId
          this.library = {}
          this.tasks = []
          this.dataSource = new MatTableDataSource<Task>(this.tasks)
          this.fetchLibraryData()
        }
      })
    } else {
      this.favoritesLibrary = true
      this.library = {}
      this.tasks = []
      this.dataSource = new MatTableDataSource<Task>(this.tasks)
      this.fetchFavoritesData()
    }

  }

  ngAfterViewInit() {
    this.dataSource.paginator = this.paginator
    this.dataSource.sort = this.sort
  }
}
