import { ClipboardModule } from '@angular/cdk/clipboard'
import { HttpClientModule, HTTP_INTERCEPTORS } from '@angular/common/http'
import { APP_INITIALIZER, NgModule } from '@angular/core'
import { getAnalytics, provideAnalytics, ScreenTrackingService, UserTrackingService } from '@angular/fire/analytics'
import { initializeApp, provideFirebaseApp } from '@angular/fire/app'
import { getAuth, provideAuth } from '@angular/fire/auth'
import { FIREBASE_OPTIONS } from '@angular/fire/compat'
import { getDatabase, provideDatabase } from '@angular/fire/database'
import { getFirestore, provideFirestore } from '@angular/fire/firestore'
import { getStorage, provideStorage } from '@angular/fire/storage'
import { FlexLayoutModule } from '@angular/flex-layout'
import { FormsModule, ReactiveFormsModule } from '@angular/forms'
import { MatBadgeModule } from '@angular/material/badge'
import { MatBottomSheetModule } from '@angular/material/bottom-sheet'
import { MatButtonModule } from '@angular/material/button'
import { MatCardModule } from '@angular/material/card'
import { MatCheckboxModule } from '@angular/material/checkbox'
import { MatChipsModule } from '@angular/material/chips'
import { MatDialogModule } from '@angular/material/dialog'
import { MatFormFieldModule } from '@angular/material/form-field'
import { MatGridListModule } from '@angular/material/grid-list'
import { MatIconModule } from '@angular/material/icon'
import { MatInputModule } from '@angular/material/input'
import { MatListModule } from '@angular/material/list'
import { MatPaginatorModule } from '@angular/material/paginator'
import { MatProgressBarModule } from '@angular/material/progress-bar'
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner'
import { MatSelectModule } from '@angular/material/select'
import { MatSidenavModule } from '@angular/material/sidenav'
import { MatSnackBarModule } from '@angular/material/snack-bar'
import { MatSortModule } from '@angular/material/sort'
import { MatTableModule } from '@angular/material/table'
import { MatTabsModule } from '@angular/material/tabs'
import { MatToolbarModule } from '@angular/material/toolbar'
import { MatTooltipModule } from '@angular/material/tooltip'
import { BrowserModule, HammerModule } from '@angular/platform-browser'
import { BrowserAnimationsModule, NoopAnimationsModule } from '@angular/platform-browser/animations'
import { ServiceWorkerModule } from '@angular/service-worker'
import { AuthModule } from '@auth0/auth0-angular'
import { CacheLocation } from '@auth0/auth0-spa-js'
import { FileOpener } from '@awesome-cordova-plugins/file-opener/ngx'
import { HTTP } from '@awesome-cordova-plugins/http/ngx'
import { InAppBrowser } from '@awesome-cordova-plugins/in-app-browser/ngx'
import { PSPDFKit } from '@awesome-cordova-plugins/pspdfkit-cordova/ngx'
import { Capacitor } from '@capacitor/core'
import { getApp } from '@firebase/app'
import { File } from '@ionic-native/file/ngx'
import { Zip } from '@ionic-native/zip/ngx'
import { MdePopoverModule } from '@material-extended/mde'
import { NgIconsModule } from '@ng-icons/core'
import {
  HeroAcademicCap,
  HeroAcademicCapSolid,
  HeroArrowsExpand,
  HeroBadgeCheck,
  HeroBan, HeroBellSolid,
  HeroChat,
  HeroChatSolid,
  HeroCheckCircle,
  HeroCheckCircleSolid,
  HeroChevronLeft,
  HeroChevronLeftSolid,
  HeroChevronRight,
  HeroChevronRightSolid,
  HeroClock,
  HeroClockSolid,
  HeroCloud,
  HeroCollection,
  HeroCollectionSolid,
  HeroCube,
  HeroCubeSolid,
  HeroDesktopComputer,
  HeroDocumentText,
  HeroDocumentTextSolid,
  HeroDuplicate,
  HeroDuplicateSolid,
  HeroExternalLink,
  HeroExternalLinkSolid,
  HeroFlagSolid,
  HeroFolderDownload,
  HeroFolderDownloadSolid,
  HeroGlobeAltSolid,
  HeroHomeSolid,
  HeroInformationCircle,
  HeroLibrary,
  HeroLibrarySolid,
  HeroLogoutSolid,
  HeroMap,
  HeroMapSolid,
  HeroMicrophone,
  HeroMicrophoneSolid,
  HeroPaperAirplane,
  HeroPhotograph,
  HeroPhotographSolid,
  HeroStar,
  HeroStarSolid,
  HeroSun,
  HeroSunSolid,
  HeroTemplate,
  HeroTemplateSolid,
  HeroThumbDown,
  HeroThumbUp,
  HeroUserCircle,
  HeroUserCircleSolid,
  HeroUsers,
  HeroUsersSolid,
  HeroVideoCamera,
  HeroVideoCameraSolid
} from '@ng-icons/heroicons'
import { indexedDBLocalPersistence, initializeAuth } from 'firebase/auth'
import { MdbAccordionModule } from 'mdb-angular-ui-kit/accordion'
import { MdbCarouselModule } from 'mdb-angular-ui-kit/carousel'
import { MdbCheckboxModule } from 'mdb-angular-ui-kit/checkbox'
import { MdbCollapseModule } from 'mdb-angular-ui-kit/collapse'
import { MdbDropdownModule } from 'mdb-angular-ui-kit/dropdown'
import { MdbFormsModule } from 'mdb-angular-ui-kit/forms'
import { MdbModalModule } from 'mdb-angular-ui-kit/modal'
import { MdbPopoverModule } from 'mdb-angular-ui-kit/popover'
import { MdbRadioModule } from 'mdb-angular-ui-kit/radio'
import { MdbRangeModule } from 'mdb-angular-ui-kit/range'
import { MdbRippleModule } from 'mdb-angular-ui-kit/ripple'
import { MdbScrollspyModule } from 'mdb-angular-ui-kit/scrollspy'
import { MdbTabsModule } from 'mdb-angular-ui-kit/tabs'
import { MdbTooltipModule } from 'mdb-angular-ui-kit/tooltip'
import { MdbValidationModule } from 'mdb-angular-ui-kit/validation'
import { NgHttpLoaderModule } from 'ng-http-loader'
import { CookieModule } from 'ngx-cookie'
import { CallbackPipe } from 'src/utilities/callback.pipe'
import { environment } from '../environments/environment'
import '../polyfills'
import { FileSizePipe } from '../utilities/file-size.pipe'
import { FromPipe } from '../utilities/from.pipe'
import { LibraryNamePipe } from '../utilities/library-name.pipe'
import { OrderByPipe } from '../utilities/OrderByPipe'
import { SafePipe } from '../utilities/safe.pipe'
import { TimeFormatPipe } from '../utilities/time-format.pipe'
import { AgendaComponent } from './agenda/agenda.component'
import { AppRoutingModule } from './app-routing.module'
import { AppComponent } from './app.component'
import { BottomSheetItemDetailComponent } from './bottom-sheet-item-detail/bottom-sheet-item-detail.component'
import { CarouselComponent } from './carousel/carousel.component'
import { ChatGptSidebarComponent } from './chat-gpt-sidebar/chat-gpt-sidebar.component'
import { ConfirmationDialogComponent } from './confirmation-dialog/confirmation-dialog.component'
import { CourseComponent } from './course/course.component'
import { ShowMoreDirective } from './directives/show-more.directive'
import { FooterComponent } from './footer/footer.component'
import { HeaderComponent } from './header/header.component'
import { HomePageComponent } from './home-page/home-page.component'
import { LayoutDetailComponent } from './layout-detail/layout-detail.component'
import { LayoutComponent } from './layout/layout.component'
import { LibraryComponent } from './library/library.component'
import { ModuleComponent } from './module/module.component'
import { MyLearningsComponent } from './my-learnings/my-learnings.component'
import { NotificationsComponent } from './notifications/notifications.component'
import { PageNotFoundComponent } from './page-not-found/page-not-found.component'
import { CompleteComponent } from './quiz/complete/complete.component'
import { LandingComponent } from './quiz/landing/landing.component'
import { QuestionComponent } from './quiz/question/question.component'
import { QuizComponent } from './quiz/quiz.component'
import { RecommendationsComponent } from './recommendations/recommendations.component'
import { CustomRoadmap } from './roadmap/custom/custom.component'
import { DailyRoadmap } from './roadmap/daily/daily.component'
import { RoadmapComponent } from './roadmap/roadmap.component'
import { WeeklyRoadmap } from './roadmap/weekly/weekly.component'
import { ScormCompletionComponent } from './scorm-completion/scorm-completion.component'
import { SearchComponent } from './search/search.component'
import { CachingInterceptorService } from './services/cache-interceptor.service'
import { DelphireInitializationService } from './services/delphire-initialization.service'
import { OrganizationService } from './services/delphire-organization.service'
import { UserService } from './services/delphire-user.service'
import { FirebaseService } from './services/firebase.service'
import { GroupService } from './services/group-service'
import { NavigationService } from './services/navigation.service'
import { PdfTrackingService } from './services/pdf-tracking.service'
import { ProgressService } from './services/tracking/progress.service'
import { TrackService } from './services/tracking/track.service'
import { WindowRef } from './services/window-ref.service'
import { SpaceComponent } from './space/space.component'
import { TaskCardComponent } from './task-card/task-card.component'
import { SelfCompleteDialog, TaskComponent } from './task/task.component'
import { TrackableDocumentModalComponent } from './trackable-document-modal/trackable-document-modal.component'
import { TrackableMultiMediaModalComponent } from './trackable-multimedia-modal/trackable-multimedia-modal.component'
import { WidgetComponent } from './widget/widget.component'

const cacheLocation: CacheLocation = 'localstorage'
export const authConfig = Capacitor.isNativePlatform()
  ? {
      domain: environment.authConfig.domain,
      clientId: environment.authConfig.clientId,
      audience: environment.authConfig.audience,
      scope: 'openid email profile',
      cacheLocation: cacheLocation,
      useRefreshTokens: true,
      redirectUri: `${environment.urlScheme}://${environment.authConfig.domain}/capacitor/${environment.urlScheme}/callback`
    }
  : {
      domain: environment.authConfig.domain,
      clientId: environment.authConfig.clientId,
      audience: environment.authConfig.audience,
      responseType: 'token id_token',
      cacheLocation: cacheLocation
    }

export function initAppUser(
  delphireInitializationService: DelphireInitializationService
) {
  return () => delphireInitializationService.initialize()
}

@NgModule({
  declarations: [
    HeaderComponent,
    AppComponent,
    PageNotFoundComponent,
    ConfirmationDialogComponent,
    OrderByPipe,
    CallbackPipe,
    SpaceComponent,
    LayoutComponent,
    LayoutDetailComponent,
    WidgetComponent,
    FooterComponent,
    RoadmapComponent,
    TaskComponent,
    SelfCompleteDialog,
    TrackableDocumentModalComponent,
    ModuleComponent,
    SafePipe,
    LibraryComponent,
    MyLearningsComponent,
    CourseComponent,
    QuizComponent,
    SearchComponent,
    NotificationsComponent,
    TrackableMultiMediaModalComponent,
    FileSizePipe,
    CarouselComponent,
    LandingComponent,
    CompleteComponent,
    QuestionComponent,
    BottomSheetItemDetailComponent,
    HomePageComponent,
    TimeFormatPipe,
    TaskCardComponent,
    LibraryNamePipe,
    ShowMoreDirective,
    DailyRoadmap,
    WeeklyRoadmap,
    CustomRoadmap,
    FromPipe,
    ScormCompletionComponent,
    RecommendationsComponent,
    AgendaComponent,
    ChatGptSidebarComponent
  ],
  entryComponents: [ConfirmationDialogComponent],
  imports: [
    provideFirebaseApp(() => {
      return initializeApp(environment.firebaseConfig)
    }),
    provideFirestore(() => {
      const firestore = getFirestore()
      // enableIndexedDbPersistence(firestore)
      return firestore
    }),
    provideDatabase(() => getDatabase()),
    provideStorage(() => getStorage()),
    provideAnalytics(() => getAnalytics()),
    provideAuth(() => {
      if (Capacitor.isNativePlatform()) {
        return initializeAuth(getApp(), {
          persistence: indexedDBLocalPersistence
        })
      } else {
        return getAuth()
      }
    }),
    BrowserModule,
    HttpClientModule,
    AuthModule.forRoot(authConfig),
    NgHttpLoaderModule.forRoot(),
    NgIconsModule.withIcons({
      HeroThumbUp,
      HeroThumbDown,
      HeroBellSolid,
      HeroGlobeAltSolid,
      HeroLogoutSolid,
      HeroLibrarySolid,
      HeroMapSolid,
      HeroChat,
      HeroCheckCircleSolid,
      HeroDocumentTextSolid,
      HeroVideoCameraSolid,
      HeroAcademicCap,
      HeroAcademicCapSolid,
      HeroBadgeCheck,
      HeroBan,
      HeroCheckCircle,
      HeroChevronLeft,
      HeroChevronLeftSolid,
      HeroChevronRight,
      HeroChevronRightSolid,
      HeroClock,
      HeroClockSolid,
      HeroCloud,
      HeroCollection,
      HeroCollectionSolid,
      HeroCube,
      HeroCubeSolid,
      HeroDesktopComputer,
      HeroDocumentText,
      HeroDuplicate,
      HeroDuplicateSolid,
      HeroArrowsExpand,
      HeroExternalLink,
      HeroExternalLinkSolid,
      HeroFlagSolid,
      HeroFolderDownload,
      HeroFolderDownloadSolid,
      HeroHomeSolid,
      HeroLibrary,
      HeroMap,
      HeroMicrophone,
      HeroMicrophoneSolid,
      HeroPaperAirplane,
      HeroPhotograph,
      HeroPhotographSolid,
      HeroInformationCircle,
      HeroStar,
      HeroStarSolid,
      HeroSun,
      HeroSunSolid,
      HeroTemplate,
      HeroTemplateSolid,
      HeroUsers,
      HeroUsersSolid,
      HeroVideoCamera,
      HeroChatSolid,
      HeroUserCircle,
      HeroUserCircleSolid
    }),
    AppRoutingModule,
    BrowserAnimationsModule,
    MdePopoverModule,
    ClipboardModule,
    HammerModule,
    MatSnackBarModule,
    MatCheckboxModule,
    MatChipsModule,
    MatBottomSheetModule,
    MatFormFieldModule,
    MatBadgeModule,
    MatTabsModule,
    MatTableModule,
    MatTooltipModule,
    MatPaginatorModule,
    MatDialogModule,
    MatSortModule,
    MatGridListModule,
    MatToolbarModule,
    MatIconModule,
    MatButtonModule,
    MatCardModule,
    MatSelectModule,
    MatProgressBarModule,
    MatProgressSpinnerModule,
    MatSidenavModule,
    MatListModule,
    FlexLayoutModule,
    MdbAccordionModule,
    MdbCarouselModule,
    MdbCheckboxModule,
    MdbCollapseModule,
    MdbDropdownModule,
    MdbFormsModule,
    MdbModalModule,
    MdbPopoverModule,
    MdbRadioModule,
    MdbRangeModule,
    MdbRippleModule,
    MdbScrollspyModule,
    MdbTabsModule,
    MdbTooltipModule,
    MdbValidationModule,
    NoopAnimationsModule,
    FormsModule,
    ReactiveFormsModule,
    MatInputModule,
    CookieModule.forRoot(),
    ServiceWorkerModule.register('ngsw-worker.js', {
      enabled: environment.production,
      // Register the ServiceWorker as soon as the app is stable
      // or after 30 seconds (whichever comes first).
      registrationStrategy: 'registerWhenStable:30000'
    })
  ],
  providers: [
    {
      provide: APP_INITIALIZER,
      useFactory: initAppUser,
      deps: [DelphireInitializationService],
      multi: true
    },
    { provide: FIREBASE_OPTIONS, useValue: environment.firebaseConfig },
    FirebaseService,
    {
      provide: HTTP_INTERCEPTORS,
      useClass: CachingInterceptorService,
      multi: true
    },
    TrackService,
    ProgressService,
    OrganizationService,
    GroupService,
    UserService,
    NavigationService,
    ScreenTrackingService,
    UserTrackingService,
    InAppBrowser,
    Zip,
    File,
    FileOpener,
    PSPDFKit,
    WindowRef,
    PdfTrackingService,
    HTTP
  ],
  bootstrap: [AppComponent]
})
export class AppModule {}
